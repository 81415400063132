import { Environment } from '../environment';

export const environment: Environment = {
    defaultLanguage: 'pl-PL',
    supportedLanguages: ['pl-PL', 'en-EN', 'es-ES'],
    production: false,
    version: '1.0.0',
    serverUrl: 'https://api.mille-canary.sandbox.cloud.codelabs.work/',
    assetsUrl: 'https://api.mille-canary.sandbox.cloud.codelabs.work/',
    cacheDurationInMinutes: 10,
    userReloadIntervalSeconds: 60,
    isAdmin: false,
    build: {
        time: true,
        commit: true,
    },
    currency: 'EUR',
};
